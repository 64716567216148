/* center a div */
.ainnovation{
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* this is a test */
.ainnovation .title{
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
}
