/* font from osume.com (technically shopify) */
@font-face {font-family: 'GTWalsheimPro-Regular';src: url('https://cdn.shopify.com/s/files/1/0588/4228/4186/files/GTWalsheimPro-Regular.woff?v=1669253354') format('woff');} 

* {
    margin: 0;
    padding: 0;
    font-family: 'GTWalsheimPro-Regular', sans-serif ;

}

html {
    /* snap scroll maybe we keep this idk */
    /* scroll-snap-type: y mandatory; */

    scroll-behavior: smooth;
}

section {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* light / dark mode colours */
/* 
    * #d98cf5 
    * #2b20f8
    * #31a2fa
*/

body {
    --colour-primary: 0, 0, 0;
    /* --colour-secondary: 64, 64, 64; */
    --colour-secondary: 16, 16, 16;
    
    --inverse-primary: 255, 255, 255;
    /* --inverse-secondary: 192, 192, 192; */
    --inverse-secondary: 240, 240, 240;
    /* --inverse-primary: #000; */
    
    --border-radius: 10px;
    --border-size: 3px;
    --transition: cubic-bezier(1,.55,.62,.37);
    
    background-color: rgb(var(--colour-primary));
    color: rgb(var(--inverse-primary));
    /* transition */
    transition: 0.4s var(--transition);
    
    /* transition: 0.4s ease-in-out; */
}


/* perlin noice field canvas */
#c{
    height: 100%;
    /* height: 45vh; */
    width: 100%;

    /* border: var(--border-size) solid rgb(var(--inverse-primary)); */
    border-radius: var(--border-radius);
    background-color: rgb(var(--colour-secondary));

    transition: 0.4s var(--transition);
}


/* scroll wheel css =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */

::-webkit-scrollbar {
    width: 0;
    /* width: 15px; */
    /* width: 100px; */
}

/* Track */
::-webkit-scrollbar-track {
    background:transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: linear-gradient(-45deg, #d98cf5,#2b20f8,#31a2fa);

    background-clip: content-box;
    border: 5px solid transparent;
    border-radius: 10px;
    /* background-image: url('https://assets.materialup.com/uploads/cde34f59-a45f-48d4-9699-3a686999b9dc/preview.gif'); */
    }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    /* background: #555; */
    opacity: 0.8;
}