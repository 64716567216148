#dark-mode-button{
    position: fixed;

    z-index: 100;

    bottom:0;
    left:0;
    
    margin:1rem;

    height: 2rem;
    aspect-ratio: 1/1;

    opacity: 0.7;

    cursor: pointer;
}

#dark-mode-button img{
    height: 100%;
    width: 100%;

    filter: invert(1);
}