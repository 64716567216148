.events {
    /* border-style: dotted; */
    position: relative;

    /* REMEMBER TO CHANGE HEIGHT BACK TO 500VH */
    /* height: 500vh; */ 
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.events .events-header{
    width: 100%;
    /* height: 150vh;     */
    margin-bottom: 80vh;
    margin-top: 40vh;

    position: sticky;
    top: 50%;
    transform: translateY(-50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.events .events-header h1{
    /* writing-mode: vertical-rl; */
    
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    /* border-style: dotted; */
    
    background-image: 
    url("https://cdn.dribbble.com/users/1770290/screenshots/6164788/bg_76.gif");
    background-size: 110% auto;
    background-position: center;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    
    max-width: 90%;
}
.events .events-header p{
    margin-top: 0.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    /* border-style: dotted; */

    width: calc(100vw - 2rem);
}

.events .events-body{
    height: 100%;
    width: 100%;

    --events-spacing: 0.5rem;
}

.events .events-body .event{
    position: sticky;
    border-radius: 1rem;

    margin: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
}

.events .event{
    height: 100%;
}

.events .event-interact{
    height: 100%;
    width: 100%;
}

/* individual event styling */
.events .events-body .event:nth-child(1){
    background: linear-gradient(45deg, #72b662ff,#3899ceff);

    height: 100vh;
    top: calc(0% + var(--events-spacing));
}
.events .events-body .event:nth-child(2){
    background: linear-gradient(45deg, #57a1bcff,#264d68ff);
    height: 97vh;
    top: calc(3% + var(--events-spacing));
}
.events .events-body .event:nth-child(3){
    background: linear-gradient(45deg, #567b8eff,#2b3246ff);
    height: 94vh;
    top: calc(6% + var(--events-spacing));
}

.events .event .event-image{
    height: 10vh;
    position: absolute;
    top: 0;
    left: 0;

    padding:1rem;
}
.events .event .event-image img{
    height: 100%;
    max-width: 100%;
    object-fit: contain;
}

/* Ainnovation event */
.ainnovation-body{
    width:100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ainnovation-podium{
    /* margin-top: 250px; */
    width:1000px;
    max-width: 100%;
    aspect-ratio: 9/2;
}