.podium{
    width:100%;
    height: 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    
    /* border-style: dotted; */
}

.podium .pillar{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    /* border-style: dotted; */

    background-color: grey;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
    border-radius: var(--border-radius);

    transform: translate(0,0);

    transition: 0.4s ease-in;

    cursor: pointer;
}

.podium .pillar .pillar-body{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.pillar .pillar-ledge{
    width: 110%;
    aspect-ratio: 9/1;
    background-color: gainsboro;

    /* background-image: url(https://cdn.dribbble.com/users/1770290/screenshots/6164788/bg_76.gif);
    background-size: cover; */

    position: absolute;
    top: 0;
    transform: translateY(-50%);
    border-radius: var(--border-radius);
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
}

.podium .pillar .pillar-body .pillar-title{
    font-size: 3rem;
    text-transform: uppercase;
    text-align: center;
}

.podium .pillar:nth-child(1){
    /* gold */
    background-color: rgb(207, 174, 98);
}
.podium .pillar:nth-child(2){
    /* silver */
    background-color: rgb(192, 192, 192);
}
.podium .pillar:nth-child(3){
    /* bronze */
    background-color: rgb(205, 127, 50);
}

.confetti{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}