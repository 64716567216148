.projects{
    height: 100vh;
}

#test {
    background-image: 
    url("https://cdn.dribbble.com/users/1770290/screenshots/6164788/bg_76.gif");
    background-size: 110% auto;
    background-position: center;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    
    font-weight: 800;

    text-decoration: underline;
    /* text-decoration-color: rgb(var(--inverse-primary)); */
}
