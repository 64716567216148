.portfolios{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    overflow: hidden;
    position: relative;

	height:100vh;
}

.portfolios .portfolios-title{
	position: absolute;
	top: 0;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	/* text-transform: lowercase; */
	text-align: center;
}

.portfolios .portfolios-title h1{
	width: calc(100vw - 2rem);
	
	font-size: 3rem;
	font-weight: 700;
	
	text-transform: lowercase;
	text-align: center;
	
	background-image: 
	url("https://cdn.dribbble.com/users/1770290/screenshots/6164788/bg_76.gif");
	background-size: 110% auto;
	background-position: center;
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
}

.portfolios .portfolios-title p{
	width: calc(100vw - 2rem);
	margin-top: 0.5rem;
    font-size: 1.5rem;
    font-weight: 500;
}

.portfolios .portfolio-card-container{
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    position: relative;
    
    transition: 0.3s ease;
}

.portfolios .portfolio-bands{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    position: absolute;
}


.portfolios .carousel .portfolio-card-s{
    position: absolute;
    top: 5vh;
    
    /* background-color: rgb(var(--inverse-secondary)); */
    /* aspect-ratio: 1/1; */
    height: 40px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    
    z-index: 100;

    transition: 0.3s var(--transition);
    opacity: 0;

    pointer-events: none;
}

.portfolios .carousel .portfolio-card-s img{
    height: 20vh;
}

.portfolios .vp-frame{
    border: 1px solid rgb(var(--inverse-primary));
    border-radius: var(--border-radius);
    background-color: rgb(var(--colour-primary));
    aspect-ratio: 1/1;
    height: 30%;
    margin: 1rem;
    /* transition: 0.2s var(--transition); */
    border-radius: var(--border-radius);

    display: flex;
    justify-content: center;
    align-items: center;

    text-transform: lowercase;
}

.portfolios .portfolio-card-bottom{
	width:100%;
	height: 100%;


	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font-size: 1.5rem;
    font-size: 3vh;
    text-transform: lowercase;
    text-align: center;  

	cursor: pointer;
}

.portfolios .portfolio-information{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.portfolios .portfolio-information .portfolio-headshots{
	
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.portfolios .portfolio-information .portfolio-description{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 90%;
	height: 90%;

	font-size: 1.5rem;
	text-transform: lowercase;

	overflow-y: scroll;
	opacity: 0;
	pointer-events: none;
}

.portfolios .portfolio-card-title{
	/* background-image: 
    url("https://cdn.dribbble.com/users/1770290/screenshots/6164788/bg_76.gif");
    background-size: 110% auto;
    background-position: center;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text; */

	font-size: 1.7rem;
	font-weight: bold;
}


.portfolios .portfolio-learn-more{
	opacity:0;
	position: absolute;
	
	width:100%;
	height:100%;

	padding:1rem;

	display: flex;
	flex-direction: column;
	justify-content: center;
	
	white-space: nowrap;
	transition: 0.2s ease;

}
.portfolios .portfolio-learn-more h2{
	
	height:100%;
	margin: 1rem;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font-size: 1.5rem;
	font-size: 3vh;
	text-transform: lowercase;
	text-align: center;

	/* background-color: rgba(var(--colour-primary),0.5); */
	border-radius: var(--border-radius);

	transition: 0.3s ease;
	
}
.portfolios .portfolio-learn-more:hover h2{
	background-image: 
    url("https://cdn.dribbble.com/users/1770290/screenshots/6164788/bg_76.gif");
    background-size: 110% auto;
    background-position: center;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;

	transform: scale(1.1);
}



/* glitch effect */

.glitch {
    position: relative;
	overflow: hidden;
}
.glitch img {
	position: relative;
	z-index: 1;
	display: block;
}
.glitch__layers {
	position: absolute;
	z-index: 2;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
.glitch__layer {
    position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	/* background-image: url(https://images.unsplash.com/photo-1561019733-a84b4e023910?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80); */
	background-size: 20vh 20vh;
    background-repeat: no-repeat;
	background-position: 0 0;
}
.glitch__layer:nth-child(1) {
	transform: translateX(-5%);
	animation: glitch-anim-1 2s infinite linear alternate;
}
.glitch__layer:nth-child(2) {
	transform: translateX(3%) translateY(3%);
	animation: glitch-anim-2 2.3s -.8s infinite linear alternate;
}
.glitch__layer:nth-child(3) {
	transform: translateX(5%);
	animation: glitch-anim-flash 1s infinite linear;
}
@keyframes glitch-anim-1 {
	0% {
		clip-path: polygon(0 0%, 100% 0%, 100% 5%, 0 5%);
	}
	10% {
		clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
	}
	20% {
		clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
	}
	30% {
		clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
	}
	40% {
		clip-path: polygon(0 35%, 100% 35%, 100% 35%, 0 35%);
	}
	50% {
		clip-path: polygon(0 45%, 100% 45%, 100% 46%, 0 46%);
	}
	60% {
		clip-path: polygon(0 50%, 100% 50%, 100% 70%, 0 70%);
	}
	70% {
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}
	80% {
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	90% {
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}
	100% {
		clip-path: polygon(0 60%, 100% 60%, 100% 70%, 0 70%);
	}
}
@keyframes glitch-anim-2 {
	0% {
		clip-path: polygon(0 15%, 100% 15%, 100% 30%, 0 30%);
	}
	15% {
		clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
	}
	25% {
		clip-path: polygon(0 8%, 100% 8%, 100% 20%, 0 20%);
	}
	30% {
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	45% {
		clip-path: polygon(0 45%, 100% 45%, 100% 45%, 0 45%);
	}
	50% {
		clip-path: polygon(0 50%, 100% 50%, 100% 57%, 0 57%);
	}
	65% {
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	75% {
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	80% {
		clip-path: polygon(0 40%, 100% 40%, 100% 60%, 0 60%);
	}
	95% {
		clip-path: polygon(0 45%, 100% 45%, 100% 60%, 0 60%);
	}
	100% {
		clip-path: polygon(0 11%, 100% 11%, 100% 15%, 0 15%);
	}
}
@keyframes glitch-anim-flash {
	0% {
		opacity: .2;
	}
	30%, 100% {
		opacity: 0;
	}
}