/* all elements should be wrapped with */
/* nav */

nav {
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;
    
    z-index: 100;
}

nav .nav-bar-content{
    position: absolute;
    
    /* border-style: dotted; */
    /* background-color: aqua; */
    height: 3rem;
    aspect-ratio: 1/1;

    right: 0;
    top:0;

    margin: 1rem;
}

nav #nav-logo{
    z-index: 100;
    opacity: 1;
}

nav #nav-logo img{
    filter: invert(0);
}

nav .nav-bar-option{
    position: absolute;
    
    height:100%;
    aspect-ratio: 1/1;
    
    border-radius: 100%;

    background-color: rgba(var(--colour-secondary),1);
    
    cursor: pointer;
    
    top:0;
    right:0;

    box-shadow: 0 0 3px 1px rgba(var(--inverse-secondary), 0.5);
    
    transition: 0.25s cubic-bezier(.55,.02,.8,1.34);

    opacity: 0;
}
nav .nav-bar-option:hover{
    transform: scale(1.1);
    transition: 0.3s ease;
}

/* join button start =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */
nav #nav-bar-container{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    
    top: 0.1rem;
    right: 4rem;
    
    height: 2rem;

    margin: 1rem;

    border-radius: 2rem;

    cursor: pointer;

    transition: 0.3s ease;
}

nav #nav-bar-container:hover{
    transform: scale(1.1);
    transition: 0.3s ease;
}

nav #nav-bar-container .nav-bar-join{
    height: 100%;
    aspect-ratio: 3/1;

    font-weight: bold;
    
    display: flex;
    align-items: center;
    justify-content: center;

    backdrop-filter: blur(10px);

    border-radius: 2rem;

    color: rgb(var(--inverse-secondary));

    user-select: none;
}

/* join button end =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */

nav .nav-bar-option img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 70%;

    transition: 0.2s var(--transition);
}

nav .nav-bar-option .nav-bar-option-title{
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    top: 50%;
    right: 100%;
    transform: translateY(-50%);

    border-radius: var(--border-radius);

    backdrop-filter: blur(5px) invert(0.2);

    background-color: rgba(var(--colour-secondary), 0.2);

    padding: 0.5rem;

    font-size: 80%;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    text-align: center;
    margin: 0 1rem;
    
    opacity: 0;
    pointer-events: none;

    color: rgb(var(--inverse-secondary));
    
    transition: 0.2s var(--transition);
}